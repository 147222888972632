@font-face {
    font-family: 'comic_sans_msbold';
    src: url('comicsansmsbold-webfont.woff2') format('woff2'),
         url('comicsansmsbold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'comic_sans_msregular';
    src: url('comic-webfont.woff2') format('woff2'),
         url('comic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

* {
	margin: 0px;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Comic Sans MS','comic_sans_msbold', sans-serif;
	font-size: 16px;
	background: white;
	overflow: auto;
	height: 100%;
	color: #184B8C;
}

p {
	font-family: 'Comic Sans MS','comic_sans_msbold', sans-serif;
	font-size: 16px;
	text-align: center;
	/*color: #000;*/
	margin: 20px;
	line-height: 1.8em;
}


a {
	text-decoration: none;
	text-align: center;
	color: #184B8C;

}

p a {
	color: #184B8C;
}
a:visited {
	color: #184B8C;
}

ul {
	list-style: none;
}


.zimu-class {
	display: flex;
	align-items: center;
	justify-content: space-between;
}


.zimu-class img {
	/*weight: 100%;*/
	object-fit: fill;
	height: 70%;
}

.container {
	/* max-width: 1200px; */
	width: 100%;
	/*margin: 30px auto;*/
}

nav {
	background: #fff;
	margin: 0 10px 0 0px;
	/*width: 100%;*/
	height: fit-content
}

.logo {
	vertical-align: middle;
	color: #184B8C;
	font-size: 40px;
	height: 90px;
	width: 260px;

}


.logo a {
	vertical-align: middle;
	color: #184B8C;
	font-size: 40px;
	height: 90px;


}


h1 {
	text-align: center;
	color: #184B8C;
	font-size: 40px;
	margin: 20px;
}


h2 {
	text-align: center;
	color: #B20000;
	font-size: 40px;
	margin: 20px;
}


h3 {
	text-align: center;
	color: #A226BC;
	font-size: 40px;
	margin: 20px;
}


/*logoumiz4kids*/
h4 {
	text-align: left;
	color: #184B8C;
	font-size: 25px;
}

h5 {
	text-align: center;
	font-size: 12px;
	font-weight: bold;


}
h6 {
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 15px;


}



.footer {

	left: 0;
	bottom: 0;
	color: #000000;
	text-align: center;
	margin: 50px 100px 50px 100px;
	font-family: 'Times New Roman', Times, serif;
	font-size: 13px;


}

.padding {
	padding-right: 10px;
}


nav .nav-items {
	display: flex;
	justify-content: space-between;
	height: 100%;
	align-items: center;
	;
}

.nav-items ul {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.nav-items ul li {
	list-style: none;
	margin-left: 20px;
	font-size: 18px;
	font-weight: 600;
	color: #000;
}

.nav-items ul li a {
	color: #184B8C;
}

.nav-items ul li a:hover {
	color: #A226BC;
}

.nav-items ul li a.active {
	color: #B20000;
}


.banner {
	font-size: 50px;
	background: #fff;
	padding: 0 20px;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.banner-container .btn {
	background: #07519A;
	color: #fff;
	padding: 10px 20px;
	border-radius: 5px;
	font-size: 18px;
	font-weight: 600;
	cursor: pointer;
}

.banner-container .btn:hover {
	background: #07519A;
}

.container .title {
	color: #07519A;
	font-size: 30px;
	font-weight: 600;
	margin: 40px 0;
}

.buecher-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 30px;
	margin: 70px 20px 0px 20px;
}

.wbbuecher-container {
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}

.buecher-container .buch-card {
	background: #fff;
	border-radius: 10px;
	/*padding: 18px;*/
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease-in-out;
	text-align: center;
}

.buecher-container .buch-card:hover {
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}


.buch-img {
	height: 200px;
	object-fit: cover;
	object-position: center;
}

.wbbuch-img {
	height: 400px;
	object-fit: cover;
	object-position: center;
}

.buch-card .buch-info {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.buch-info .buch-name {
	font-size: 24px;
	font-weight: 600;
	color: #000;
}

.buch-info .btn {
	background: #07519A;
	color: #fff;
	border: none;
	padding: 5px 10px;
	border-radius: 5px;
	font-size: 16px;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.buch-info .btn:hover {
	background: #07519A;
}



/* error component styling */

.error-page {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 20px;
	height: 60vh;
}

.error-page .error-code {
	font-size: 150px;
	font-weight: 600;
	color: #00a8ff;
}

.error-page .error-text {
	font-size: 34px;
	font-weight: 600;
	color: #000;
}

/* Single Product */
.flex-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}

.flex-container .buch-img {
	width: 100%;
	height: 500px;
	object-fit: cover;
	object-position: center;
	border: 5px solid #00a8ff;
	border-radius: 5px;
}

.buch-infos .row {
	margin: 10px 0;
}

.buch-infos .row .label {
	font-size: 18px;
	font-weight: 600;
	color: #000;
}



.divWithHandCursor {
	cursor: pointer;
	/* background-color: rgba(188, 223, 14, 0.05); */
}





.Kategorie {
	margin: -120px -80px -50px 0px;
}

.banner {
	display: flex;
	justify-content: center;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
}


.link-div {
	width: 49.99%;
	padding-bottom: 50px;
	text-align: center;
}

.link-div a img{
	width: 70%;;
}


.about-title {
	margin: 70px 100px 0px 100px;
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
	text-align: center;

}


.about-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	/*grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));*/
	grid-gap: 30px;

	margin: 70px 100px 0px 100px;
	text-align: center;
}




.scene {
	width: 60px;
	height: 60px;
	perspective: 600px;
	/* nebeneinander */
	/*  margin: 0; */
	/* untereinander */
	/* margin: 0 auto; */
	margin: auto;
}

.card {
	width: 100%;
	height: 100%;
	transition: transform 1s;
	transform-style: preserve-3d;
	cursor: pointer;
	position: relative;
}

.card.is-flipped {
	transform: rotateY(180deg);
}

.secondCard.is-flipped {
	transform: rotateY(-180deg);
}

.card__face {
	position: absolute;
	width: 100%;
	height: 100%;
	line-height: 90px;
	color: white;
	text-align: center;
	font-weight: bold;
	font-size: 40px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.flagimg{
	height: 60px;
}

.card__face--back {
	transform: rotateY(180deg);
	z-index: 999;
}

.umizFooter {
	width: 100px;
	height: 80px;
}

.bkaFooter {
	width: 180px;
	height: 32px;

}



.headimage img {
    height: 200px;
    max-width: 280px;
}

.grid-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 100px;
}

.grid-item {
	text-align: center;
	justify-content: center;
	margin: 0 auto;
}

.vokabel {
	display: grid;
	grid-template-columns: auto auto;
}

.flags {
	margin-top: 60px;
}


.video {
	margin-top: 50px;
	margin-left: 100px;
	margin-right: 100px;
	text-align: center;

}

.video iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
}



.about-container img {
	width: 100%;
}


.vbgrid {
	display: grid;
	grid-template-columns: 80px 1fr 80px;
	height: 90%;
}

.vbgrid-side {
	width: 80px;
	margin: auto;
}

.vbgrid-center {
	position: relative;
	text-align: center;
	margin: auto;
}

.vbgrid-center-img {
	max-width: 100%;
}

.vbgrid-center img {
	max-height: calc(100vh - 90px);
	/* object-fit: cover; */
}

.arrows {
	font-size: 160px;
}

.slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin: auto;
}

.legal{
	margin-left: 25%;
	margin-right: 25%;
}

.legal p{
	text-align: left;
}

hr {
	border: none;
	border-top: 1px dotted #184B8C;;
	color: #fff;
	background-color: #fff;
	height: 10px;
	margin-top: 10px;

  }

  .legal h4{
	margin-left: 20px;
  }

.footer p{
	line-height: normal;
	color: #184B8C;
	margin-bottom: 5px;
	margin-top: 5px;
	font-size: 13px;
}

.surroudingDiv{
	border-color: #B20000;
}


/* Add this to your existing CSS styles */
.burger-icon {
	display: none; /* Hide burger icon on larger screens */
	cursor: pointer;
	font-size: 30px;
  }
  
  .menu-open {
	display: flex;
	flex-direction: column;
  }
  
  @media (max-width: 768px) {
	/* Show burger icon on smaller screens */
	.burger-icon {
	  display: block;
	}
  
	/* Hide the menu on smaller screens by default */
	.nav-items ul {
	  display: none;
	}
  
	/* Show the menu when the burger icon is clicked */
	.nav-items ul.menu-open {
	  display: flex;
	  flex-direction: column;
	}
  }
  